import { createRouter, createWebHistory } from 'vue-router'
import HomeCarousel from '../views/HomeCarousel.vue'
import Wizard from '../views/Wizard.vue'
import RsvpSample from '../views/RsvpSample.vue'
import OpenSample from '../views/OpenSample.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsOfUse from '../views/TermsOfUse.vue'

const routes = [
  {
    path: '/',
    name: 'HomeCarousel',
    component: HomeCarousel
  },
  {
    path: '/wizard',
    name: 'Wizard',
    component: Wizard
  },
  {
    path: '/rsvp-sample',
    name: 'RsvpSample',
    component: RsvpSample
  },
  {
    path: '/open-sample',
    name: 'OpenSample',
    component: OpenSample
  },
  {
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy
  },
	 {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: TermsOfUse
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
