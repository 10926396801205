<template>
Wizard
</template>
<script>
export default 
{
	name: 'Wizard',
}
</script>
<style>
</Style>
