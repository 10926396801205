<template>
Open
</template>
<script>
export default 
{
	name: 'OpenSample',
}
</script>
<style>
</Style>
