<template>
<!-- navbar start -->
<nav class="navbar navbar-expand-lg navbar-dark" style="background-color:#ff9999;">
  <div class="container-fluid">
    <a class="navbar-brand" href="#" ><h1 class="font-cookie">Wedding Invitation Cards</h1></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
					<router-link to="/">
	          <a class="nav-link active" aria-current="page" href="#">Home</a>
					</router-link>
        </li>
      </ul>
    </div>
  </div>
</nav>
<hr/>
<!-- navbar end -->
</template>
<script>
export default {
  name: 'Menu',
}
</script>
<style>
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,1);
}
.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
    color: #262626;
}
hr
{
	margin: 0px;
}
.navbar
{
	padding: 0px;
}

li a 
{
	text-decoration: none;
}
</style>
