<template>
<!--landing page start-->
<div class="row entire-viewport">
<!--carousel start-->
<div class="col-sm-12 col-md-9 col-lg-9 grey-background">
<div id="carouselExampleCaptions" class="carousel carousel-dark slide carousel-fade" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="https://d1borbdfs636ui.cloudfront.net/home-page/arpit-shreya.png" class="my-carousel-image" alt="1">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="https://d1borbdfs636ui.cloudfront.net/home-page/prithviraj-shivani3.png" class="my-carousel-image" alt="2">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
  </div>
<!--
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
		<span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
		<span class="visually-hidden">Next</span>
  </button>
-->
</div>
</div>
<!-- carousel end -->

<!--info card start-->
<div class="col-sm-12 col-lg-3 col-md-3 grey-background mt-sm-2">
<div class="card my-carousel-image" style="width: 90%; margin: auto;">
  <div class="card-body table-responsive">
		<h1 class="font-cookie"> Discover our Packages </h1>
<!--accordion start-->
<div class="accordion" id="accordionExample">
<div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
       Digital Invitation Card
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p>Custom Invitation Card is delivered in PDF format</p>
    <table class="table table-hover mx-auto w-auto">
    <tbody>
    <tr><td class="text-start"><i class="bi-pin-angle-fill pink-font"></i></td><td class="text-start"> Your Wedding Venue</td></tr>
    <tr><td class="text-start"><i class="bi-cloud-moon-fill pink-font"></i></td><td class="text-start"> Your Wedding Time </td></tr>
    <tr><td class="text-start"><i class="bi-people-fill pink-font"></i></td><td class="text-start"> Your personalised Couple Avatar </td></tr>
    <tr><td class="text-start"><i class="bi-basket-fill pink-font"></i></td><td class="text-start"> "Save The Date(s)" Element </td></tr>
    <tr><td class="text-start"><i class="bi-signpost-fill pink-font" ></i></td><td class="text-start"> Wedding Details Signboard </td></tr>
    <tr><td class="text-start"><i class="bi-pencil-square pink-font" ></i></td><td class="text-start"> Your Invitation Message</td></tr>
    <tr><td class="text-start"><i class="bi-image-fill pink-font" ></i></td><td class="text-start"> Custom Image Elements</td></tr>
    </tbody>
    </table>
    <br/>
<!--      <a href="./ArpitWedsShreya.pdf" class="btn btn-sm pink-background">View Sample Invitation Card</a> -->
<!--      <button id="myButton" class="btn pink-background btn-sm" @click="readFile()"> View Sample Invitation Card </button> -->
      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        E-Invitation Card
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p>Custom Invitation Card in PDF format is hosted online on our website</p>
    <table class="table table-hover mx-auto w-auto">
    <tbody>
    <tr><td class="text-start"><i class="bi-pin-angle-fill pink-font"></i></td><td class="text-start"> Your Wedding Venue</td></tr>
    <tr><td class="text-start"><i class="bi-cloud-moon-fill pink-font"></i></td><td class="text-start"> Your Wedding Time </td></tr>
    <tr><td class="text-start"><i class="bi-people-fill pink-font"></i></td><td class="text-start"> Your personalised Couple Avatar </td></tr>
    <tr><td class="text-start"><i class="bi-basket-fill pink-font"></i></td><td class="text-start"> "Save The Date(s)" Element </td></tr>
    <tr><td class="text-start"><i class="bi-signpost-fill pink-font" ></i></td><td class="text-start"> Wedding Details Signboard </td></tr>
    <tr><td class="text-start"><i class="bi-pencil-square pink-font" ></i></td><td class="text-start"> Your Invitation Message</td></tr>
    <tr><td class="text-start"><i class="bi-image-fill pink-font" ></i></td><td class="text-start"> Custom Image Elements</td></tr>
    </tbody>
    </table>
    <br/>
<!--			<a href="./ArpitWedsShreya.pdf" class="btn btn-sm pink-background">View Sample Invitation Card</a> -->
<!--      <button id="myButton" class="btn pink-background btn-sm" @click="readFile()"> View Sample Invitation Card </button> -->
      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Online Animated Open Invitation
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p>Custom Animated Invitation Card is hosted on our website</p>
    <table class="table table-hover mx-auto w-auto">
    <tbody>
    <tr><td class="text-start"><i class="bi-star-fill pink-font" ></i></td><td class="text-start"> Includes Website Hosting Fees</td></tr>
    <tr><td class="text-start"><i class="bi-image-fill pink-font" ></i></td><td class="text-start"> Custom Image Elements</td></tr>
   <tr><td class="text-start"><i class="bi-pin-angle-fill pink-font"></i></td><td class="text-start"> Your Wedding Venue</td></tr>
    <tr><td class="text-start"><i class="bi-cloud-moon-fill pink-font"></i></td><td class="text-start"> Your Wedding Time </td></tr>
    <tr><td class="text-start"><i class="bi-people-fill pink-font"></i></td><td class="text-start"> Your personalised Couple Avatar </td></tr>
    <tr><td class="text-start"><i class="bi-basket-fill pink-font"></i></td><td class="text-start"> "Save The Date(s)" Element </td></tr>
    <tr><td class="text-start"><i class="bi-signpost-fill pink-font" ></i></td><td class="text-start"> Wedding Details Signboard </td></tr>
    <tr><td class="text-start"><i class="bi-pencil-square pink-font" ></i></td><td class="text-start"> Your Invitation Message</td></tr>
    </tbody>
    </table>
    <br/>
<!--
    <a href="https://wedding.invitation-cards.com/arpit-weds-shreya/" target="_blank">
      <button id="myButton" class="btn pink-background btn-sm"> View Custom Invitation </button>
    </a>
-->
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        Online Animated RSVP Invitation
      </button>
    </h2>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
		<p>Custom Animated Invitation Card with account management is hosted on our website</p>
    <table class="table table-hover mx-auto w-auto">
    <tbody>
    <tr><td class="text-start"><i class="bi-envelope-fill pink-font" ></i></td><td class="text-start"> E-mail option to send Invitation Link</td></tr>
    <tr><td class="text-start"><i class="bi-diagram-2-fill pink-font" ></i></td><td class="text-start"> Online RSVP option for Invitees</td></tr>
    <tr><td class="text-start"><i class="bi-bar-chart-line-fill pink-font" ></i></td><td class="text-start"> Statistics regarding your Guests' Response</td></tr>
    <tr><td class="text-start"><i class="bi-star-fill pink-font" ></i></td><td class="text-start"> Includes Website Hosting Fees</td></tr>
    <tr><td class="text-start"><i class="bi-image-fill pink-font" ></i></td><td class="text-start"> Custom Image Elements</td></tr>
   <tr><td class="text-start"><i class="bi-pin-angle-fill pink-font"></i></td><td class="text-start"> Your Wedding Venue</td></tr>
    <tr><td class="text-start"><i class="bi-cloud-moon-fill pink-font"></i></td><td class="text-start"> Your Wedding Time </td></tr>
    <tr><td class="text-start"><i class="bi-people-fill pink-font"></i></td><td class="text-start"> Your personalised Couple Avatar </td></tr>
    <tr><td class="text-start"><i class="bi-basket-fill pink-font"></i></td><td class="text-start"> "Save The Date(s)" Element </td></tr>
    <tr><td class="text-start"><i class="bi-signpost-fill pink-font" ></i></td><td class="text-start"> Wedding Details Signboard </td></tr>
    <tr><td class="text-start"><i class="bi-pencil-square pink-font" ></i></td><td class="text-start"> Your Invitation Message</td></tr>
    </tbody>
    </table>
    <br/>
      </div>
    </div>
  </div>
</div>
<!--accordion end-->
  </div>
</div>
</div>
<!--info card end-->
</div>
<!--landing page end-->

<!-- Pricing Options start-->
<div class="row entire-viewport">
	<div class="container">
		<br/><br/>
		<h1 class="font-cookie"> Pricing </h1>
<div class="table-responsive">

    <table class="table table-striped mx-auto w-auto">
		<thead>
		 <tr>
     <th scope="col" class="text-start">Feature</th>
     <th scope="col">Digital Invitation Card</th>
		 <th scope="col">E-Invitation Card</th>
     <th scope="col">Online Animated Open Invitation</th>
     <th scope="col">Online Animated RSVP Invitation</th>
     </tr>
    </thead>
    <tbody>
 <tr><td class="text-start"> Venue Selection </td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td></tr>
		<tr><td class="text-start"> Day/Evening/Night Wedding </td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td></tr>
    <tr><td class="text-start"> Personalised Couple Avatar </td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td></tr>
    <tr><td class="text-start"> "Save The Date(s)" Element </td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td></tr>
    <tr><td class="text-start"> Wedding Details Signboard </td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td></tr>
    <tr><td class="text-start"> Custom Invitation Message </td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td></tr>
    <tr><td class="text-start"> Custom Image Elements </td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td></tr>
    <tr><td class="text-start"> Online Hosting </td><td></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td><td ><i class="bi-check-circle-fill pink-font" ></i></td></tr>
    <tr><td class="text-start"> Online RSVP for Invitees </td><td></td><td></td><td></td><td ><i class="bi-check-circle-fill pink-font" ></i></td></tr>
		<tr><td class="text-start"><h5>Price in Euros <br/> Price in INR</h5></td><td><h5>30 Euros <br/> 2500 INR</h5></td><td><h5>35 Euros<br/> 3000 INR</h5></td><td><h5>95 Euros <br/> 8000 INR</h5></td><td><h5>120 Euros <br/> 10000 INR</h5></td></tr>
		<tr><td class="text-start"><h5>Delivery Time</h5></td><td><h5>1 week</h5></td><td><h5>1 week</h5></td><td><h5>2 weeks</h5></td><td><h5>2 weeks</h5></td></tr>
    <tr><td class="text-start"><h5>Delivery Item</h5></td><td><h5>PDF</h5></td><td><h5>Online hosted PDF</h5></td><td><h5>Website</h5></td><td><h5>Website with account management</h5></td></tr>
    </tbody>
    </table>
		</div>
		<br/>
    <div class="row justify-content-center text-danger">
    <h1 class="font-cookie"> Offers & Payment Information </h1>
		<span> 15% Discount for orders via referral. </span>
    <span> 20% Discount on combo offer when both PDF and Website are ordered. </span>
    <span> 50% upfront payment on order confirmation. 50% payment after delivery. </span>
    <span> Payment can be done either in Euros via Paypal or in INR via Paytm. </span>
    </div>
		<br/>
	</div>
  <div class="container-fluid grey-background pt-5">
	<h2 class="font-cookie">What is the difference between RSVP Invitation and Open Invitation?</h2>
	<br/>
  <h4 class="pink-font">RSVP Invitation</h4> 
	<p> A unique Invitation Link is automatically sent by e-mail to each invitee. <br/> The invitee can choose to respond "Yes" or "No" to your online invitation. <br/> You can view the individual response of your invitees and other statistics within your account. </p>
	<br/><br/>
  <h4 class="pink-font">Open Invitation</h4> 
	<p>A single Invitation Link is generated. <br/> There is no option for the invitees to respond directly to your online invitation. <br/> You can manually send the Invitation Link to your guests via e-mail or share the link on Social Media. </p> 
	<br/><br/>
  </div>


</div>
<!-- Pricing Options end-->

<div class="row justify-content-around m-3">
<h1 class="font-cookie"> Testimonials </h1>
	<div class="row justify-content-around">
  <div class="card col-sm-12 col-md-5 col-lg-3 m-sm-2">
    <div class="card-header text-danger font-cookie">
			<h2>  Devanshi </h2>
    </div>

    <div class="card-body">
			<blockquote>This is really beautiful.I really loved your work and appreciate the efforts you put in for accomodating requests and specialised customization.
Thank you so much for making my ceremony a little more beautiful with a perfect invitation card.Much appreciated!!!!</blockquote>
    </div>
  </div>

  <div class="card col-sm-12 col-md-5 col-lg-3 m-sm-2">
    <div class="card-header text-danger font-cookie">
			<h2>  Komal </h2>
    </div>

    <div class="card-body">
    <blockquote>You have done a really good job. I am glad I decided to work with you. Thank u for making such a beautiful e card</blockquote>
    </div>
  </div>
	</div>
<div class="pt-5"></div>
</div>

<!-- Form start -->
<div class="row half-viewport grey-background">
	<div class="col-lg-6 col-md-6 offset-md-3 offset-lg-3">
		<br/>
    <h1 class="font-cookie"> Contact Us </h1>

				Like our packages but are interested in viewing some samples? <br/>
				Check some of our animated wedding invitations here: <br/>
				<a href="https://wedding.invitation-cards.com/arpit-weds-shreya/" target="_blank">Arpit Weds Shreya</a><br/>
				<a href="https://wedding.invitation-cards.com/prithviraj-weds-shivani/" target="_blank">Prithviraj Weds Shivani</a>
        <br/><br/>
				Mail your requirements & we'll get back to you within 24 hours:<br/>
				custom.wedding.invitation.cards@gmail.com

<!--		<form>
		<div class="mb-3">
      <label for="name" class="form-label">Your E-mail Address</label>
      <input v-model="email" type="email" name="mail" class="form-control" id="email" placeholder="Your E-mail Address" required>
    </div>

		<div class="mb-3">
			<label for="name" class="form-label">Bride's Name</label>
			<input v-model="bride" type="text" name="bride" class="form-control" id="bridename" placeholder="Bride's Full Name" required>
		</div>
    <div class="mb-3">
      <label for="name" class="form-label">Groom's Name</label>
      <input v-model="groom" type="text" name="groom" class="form-control" id="groomname" placeholder="Groom's Full Name" required>
    </div>
		<br/>
    <div class="mb-3">
      <label for="name" class="form-label">Date</label>
      <input v-model="date" type="text" name="date" class="form-control" id="date" placeholder="Wedding Date (DD/MM/YYYY)" required>
    </div>
    <br/>
		Select Time of Wedding
		<div class="form-check">
			<input v-model="time" class="form-check-input" type="radio" name="time" value="day" id="flexRadioDefault1">
			<label class="form-check-label" for="flexRadioDefault1">
			Day Wedding
			</label>
		</div>
		<div class="form-check">
			<input v-model="time" class="form-check-input" type="radio" name="time" value="evening" id="flexRadioDefault2" checked>
			<label class="form-check-label" for="flexRadioDefault2">
			Evening Wedding
		</label>
    </div>
    <div class="form-check">
      <input v-model="time" class="form-check-input" type="radio" name="time" value="night" id="flexRadioDefault3">
      <label class="form-check-label" for="flexRadioDefault3">
      Night Wedding
    </label>
		</div>
		<br/>
		<select v-model="venue" name="venue" class="form-select" aria-label="venue" required>
			<option value="">Select a Venue for your Wedding</option>
			<option value="1">Beach</option>
			<option value="2">Lawn/Garden/Park/Meadows</option>
			<option value="3">Lakeside</option>
      <option value="4">Hillside</option>
      <option value="5">Banquet Rooftop</option>
      <option value="6">Indian Palace Courtyard</option>
      <option value="7">French Chateau Courtyard</option>
      <option value="8">Other</option>
		</select>
		<br/>
    <div class="mb-3">
      <label for="name" class="form-label">Discount or Referral Code</label>
      <input v-model="code" type="text" name="code" class="form-control" id="code" placeholder="Discount or Referral Code" required>
    </div>
	  <br/>
		<div class="mb-3">
			<label for="exampleFormControlTextarea1" class="form-label">Additional Information</label>
			<textarea name="info" class="form-control" id="additional" rows="5"></textarea>
		</div>
		<button class="btn btn-warning btn-md" @click="loadDoc">Submit</button>
		</form>
-->
		<br/>
   <div class="alert alert-success alert-dismissible" role="alert" v-bind:class="{ hideAlert: isHidden }">
      Thank you for submitting your details. We will get in touch with you soon!
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>

	</div>
  
</div>
<!-- Form end--> 

</template>

<script>

export default {
  name: 'HomeCarousel',
  data(){
		return {
     isHidden: true,
		 email:"",
		 bride:"",
     groom:"",
     time:"evening",
     venue:"",
     date:"",
     code:""
    }
  },
  methods: {
      readFile() {
          window.open("static/ArpitWedsShreya.pdf") //to open in new tab
       },
      loadDoc(event) {
				event.preventDefault();
        let data = new FormData();
        data.append("emai", this.email);
        data.append("bride", this.bride);
        data.append("groom", this.groom);
        data.append("time", this.time);
        data.append("venue", this.venue);
        data.append("date", this.date);
        data.append("code", this.code)

        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = (event) => {
          if (xhttp.readyState == 4 && xhttp.status == 200) {
						this.isHidden = false;
						setTimeout(() => {
							this.isHidden = true;
						}, 5000)
          }
        };
        xhttp.open("POST", "/contact.php");
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhttp.send(data);
      }

	}
}

</script>

<style>

.hideAlert {
	display: none;
}

.my-carousel-image
{
		height: 90vh;		
    object-fit: cover;
}
.carousel-caption
{
	color: black !important;
}
.grey-background
{
	background: #f2f2f2;
}
.pink-font
{
	color: #ff9999;
}
.row
{
	margin: 0px;
}
.entire-viewport
{
	min-width: 98vw;
	min-height: 85vh;
}
.half-viewport
{ 
  min-width: 98vw;
  min-height: 50vh;
}
.pink-background
{
	background-color: #ffcccc;
}
.font-left
{
	text-align: left !important;
}
.font-cookie
{
	font-family: 'Cookie', cursive;
}
</style>



