<template>
RSVP
</template>
<script>
export default 
{
	name: 'RsvpSample',
}
</script>
<style>
</Style>
