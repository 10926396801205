<template>
<!--navbar-->
<nav class="navbar navbar-expand-lg navbar-dark" style="background-color:#ff9999;">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
					<router-link to="/privacy-policy">
	          <a class="nav-link" href="#">Privacy Policy</a>
					</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/terms-of-use">
	          <a class="nav-link" href="#">Terms of Use</a>
					</router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">Cookies Policy</a>
        </li>
      </ul>
      <form class="d-flex">
        &copy; wedding.invitation-cards.com. &nbsp; All Rights Reserved.
      </form>
    </div>
  </div>
</nav>
<!--navbar-->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">Cookies Policy</h4>
      </div>
      <div class="modal-body">
        <span>The application does not use any cookies.</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success btn-sm" data-bs-dismiss="modal">Got it</button>
      </div>
    </div>
  </div>
</div>

</template>
<script>
export default {
  name: 'Footer',
}
</script>
<style scoped>
.navbar>.container-fluid
{
	justify-content: center !important;
}
</style>
